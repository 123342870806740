import http from 'http.js';

export async function getPopularSpecialties(type) {
    const url = '/api/base/v1/popular-specialties/';
    let res = [];
    await http
        .get({
            url: url,
            data: {show: type},
        })
        .then((response) => {
            res = response;
        })
        .catch(() => {
            console.error('Failed to load popular specialties');
            return res;
        });
    return res;
}
