import {
    computed,
    configure,
    observable,
    reaction,
    makeObservable,
    toJS,
} from 'mobx';
import React from 'react';

import http from 'http.js';
import query from 'query.js';

import {
    COMMUNITIES,
    FACTSHEETS,
    formatDisplayedResultsFAP,
    HOSPITALS,
    HOSPITAL_LOCALITIES,
    LOCALITIES,
    ORGANISATION_PROFILES,
    PRACTICE_GROUPS,
    PROMOTED_PROFILES,
    PROVIDER_TYPES_IN_LOCALITIES,
    PROVIDER_TYPES,
    SPECIAL_INTERESTS,
    SPECIALTIES,
    SPECIALTIES_IN_LOCALITIES,
    SPECIALTIES_IN_HOSPITALS,
    SPECIAL_INTERESTS_IN_LOCALITIES,
    USERPROFILES,
    THREADS,
    TELEHEALTH_SPECIALTIES,
    STATE,
} from 'base/search.js';
import autobind from 'common/decorators/autobind.js';
import {asyncThrottle} from 'common/throttle.js';
import {
    localStorageGetItem,
    localStorageSetItem,
    storageIsAvailable,
    sessionStorageRemoveItem,
} from 'core/utils.js';
import Store from 'core/stores/Store.js';
import {BP, BP_FHIR, MD} from 'core/stores/ParamStore.js';
import {
    SearchLocalityStore,
    StateLocalityStore,
} from 'search/stores/SearchLocalityStore.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';
import {
    ARROW_DOWN,
    ARROW_UP,
    ENTER,
    ESC,
    ESCAPE,
    TAB,
    INTEGRATED_REFERRALS_PROMOTION,
    SEARCH_EXPLAINER_DISABLED,
    SEARCH_LOCALITY,
    SEARCH_STATE,
    FIND_A_PROVIDER,
    SEARCH_QUERY,
} from 'core/constants.js';

// Throws if observable is changed outside of action
configure({enforceActions: 'observed'});
const BUPA_ID = 39;
export default class SearchStore extends Store {
    constructor(rootStore) {
        super();

        this.rootStore = rootStore;

        this.searchLocalityStore = new SearchLocalityStore(this);
        this.stateLocalityStore = new StateLocalityStore();

        makeObservable(this, {
            searchQuery: observable,
            specialty: observable,
            interest: observable,
            locality: observable,
            displayResults: observable,
            results: observable,
            facets: observable,
            isHealthFundWidget: observable,
            isHospitalEnabled: observable,
            isReferralsPage: observable,
            localityFetchedFromStorage: observable,
            missingResultPressed: observable,
            cachedPromotedProfiles: observable,
            promotedProfiles: observable,
            promotedSpecialty: observable,
            promotedLocality: observable,
            providerType: observable,
            telehealth: observable,
            explainerDisabled: observable,
            promotionalMessage: observable,
            promotionalMessageModified: observable,
            bpImage: observable,
            bpImageUrl: observable,
            mdImageSmall: observable,
            mdImageLarge: observable,
            mdImageUrl: observable,
            searchFieldsVisible: observable,

            resetCalled: observable,
            isClientReferralsPage: observable,
            hasFocus: observable,
            resultQueryToggled: observable,
            resultsLength: computed,
            displayPopularSpecialties: computed,
            displayCompleteSearchForm: computed,
            searchTypes: computed,
            hasSpecialty: computed,
            hasSpecialtyAndLocality: computed,
            hasSpecialtyAndState: computed,
            flattenedResults: computed,
            localityURL: computed,
            isInitialClientReferralsState: computed,
            shouldRedirect: computed,
            hasPromotionalImage: computed,
        });

        this.SEARCH_URL = '/api/search/v1/all/';
        this.SORT_ORDER = [
            PROVIDER_TYPES_IN_LOCALITIES,
            PROVIDER_TYPES,
            LOCALITIES,
            SPECIALTIES,
            SPECIALTIES_IN_LOCALITIES,
            SPECIAL_INTERESTS_IN_LOCALITIES,
            SPECIALTIES_IN_HOSPITALS,
            HOSPITALS,
            TELEHEALTH_SPECIALTIES,
            SPECIAL_INTERESTS,
            USERPROFILES,
            PRACTICE_GROUPS,
            FACTSHEETS,
            COMMUNITIES,
            ORGANISATION_PROFILES,
            THREADS,
        ];

        reaction(
            () => this.specialty?.id,
            (specialtyId) => {
                if (specialtyId) {
                    this.updateStore({
                        missingResultPressed: false,
                        resultQueryToggled: true,
                        searchQuery: this.specialty?.text || this.searchQuery,
                    });
                }
            },
        );

        reaction(
            () => this.interest?.id,
            (interestId) => {
                if (interestId) {
                    this.updateStore({
                        resultQueryToggled: true,
                        searchQuery: this.interest?.text || this.searchQuery,
                    });
                    this.stateLocalityStore.updateStore({
                        showResults: true,
                    });
                }
            },
        );

        reaction(
            () => this.providerType?.id,
            (providerTypeId) => {
                if (providerTypeId) {
                    this.updateStore({
                        missingResultPressed: false,
                        resultQueryToggled: true,
                        searchQuery:
                            this.providerType?.text || this.searchQuery,
                    });
                }
            },
        );

        reaction(
            () => this.isHospitalEnabled,
            () => {
                this.searchLocalityStore.updateStore({url: this.localityURL});
            },
        );

        reaction(() => this.searchLocalityStore.selected, this.searchRedirect);

        reaction(() => this.stateLocalityStore.selected, this.searchRedirect);

        reaction(
            () => this.searchQuery,
            () => {
                this.search();
                this.updateStore({missingResultPressed: false});
            },
        );

        reaction(
            () => this.hasFocus,
            () => {
                if (this.hasFocus) {
                    this.search();
                }
            },
        );

        reaction(
            () => this.displayResults,
            () => {
                const mainElement = document.querySelector('#referrals-app');
                if (mainElement) {
                    this.setMainHeight(mainElement);
                }
            },
        );

        reaction(
            () => this.flattenedResults,
            async () => {
                this.resetPromoted();
                const shouldCallPromoted = this.shouldCallPromotedAPI;
                if (shouldCallPromoted) {
                    const result = this.flattenedResults[0];
                    const specialty = result.extra.specialtyIds[0];
                    const specialtyName = result.extra.specialties[0];
                    const locality = result.extra.localityIds[0];
                    const localityName = result.extra.location;
                    if (specialty && locality) {
                        const cached = this.cachedPromotedProfiles[
                            `${specialty} ${locality}`
                        ];
                        let promotedProfiles;
                        if (cached) {
                            promotedProfiles = cached;
                        } else {
                            const url =
                                '/api/professionals/v1/promoted-profiles/';
                            const data = {
                                specialty,
                                locality,
                                show: 'referrals',
                            };
                            const response = await http.get({url, data});
                            if (response) {
                                promotedProfiles = response
                                    .map((d) => snakeToCamelObjectKeys(d))
                                    .slice(0, 2);
                                this.cachedPromotedProfiles[
                                    `${specialty} ${locality}`
                                ] = promotedProfiles;
                            }
                        }
                        if (promotedProfiles) {
                            const promoted = promotedProfiles.map(
                                (profile) => {
                                    if (profile.practiceData?.length) {
                                        const locality = profile.practiceData
                                            .filter((practice) => {
                                                const {
                                                    localityName,
                                                    localityPostCode,
                                                    localityState,
                                                } = practice;
                                                return (
                                                    localityName &&
                                                    localityState &&
                                                    localityPostCode
                                                );
                                            })
                                            .reduce(
                                                (localityStr, current) =>
                                                    `${localityStr}${
                                                        localityStr.length
                                                            ? ', '
                                                            : ''
                                                    }${current.localityName} ${
                                                        current.localityState
                                                    } ${
                                                        current.localityPostCode
                                                    }`,
                                                '',
                                            );

                                        return {
                                            ...profile,
                                            objectType: PROMOTED_PROFILES,
                                            specialty: specialtyName,
                                            locality,
                                        };
                                    } else {
                                        return profile;
                                    }
                                },
                            );
                            this.updateStore({
                                results: {
                                    ...this.results,
                                    [PROMOTED_PROFILES]: promoted,
                                },
                                promotedProfiles: promoted,
                                promotedSpecialty: specialtyName,
                                promotedLocality: localityName,
                            });
                        }
                    }
                }
            },
        );

        this.throttledSearch = asyncThrottle(
            this.doSearch.bind(this),
            200,
            this.searchError,
        );
    }

    domStorageAvailable = storageIsAvailable();

    telehealth = false;
    explainerDisabled = false;
    promotionalMessage = '';
    promotionalMessageModified = '';
    bpImage = '';
    bpImageUrl = '';
    mdImageSmall = '';
    mdImageLarge = '';
    mdImageUrl = '';

    searchQuery = ''; // value typed into "Search" field
    specialty = null; // selected from "Search" field results
    interest = null; // selected from "Search" field results
    locality = null; // selected from "Location" field results
    providerType = null; // selected from "Search" field results
    searchFieldsVisible = false;

    cachedPromotedProfiles = {};
    promotedProfiles = [];
    promotedSpecialty = '';
    promotedLocality = '';

    // SINGLE INPUT FIELD SEARCH RESULTS
    displayResults = false;
    results = {};
    facets = [];

    resetCalled = false;

    // this is true if the locality has been fetched from storage
    // rather than manually inputted
    localityFetchedFromStorage = false;
    missingResultPressed = false;

    // Whether or not it's the referrals home page. False by default but gets
    // set to true in the ReferralsApp
    isReferralsPage = false;

    isHealthFundWidget = false;
    isHospitalEnabled = false;

    // used to determine whether search is animated or not
    isClientReferralsPage = false;
    hasFocus = false;

    // this is set to true when searchQuery has been populated by a specialty or interest
    resultQueryToggled = false;

    get isInitialClientReferralsState() {
        return (
            this.isClientReferralsPage &&
            !this.hasFocus &&
            !this.searchQuery.length
        );
    }

    get localityURL() {
        let localityURL = `/api/search/v1/all/?types=${LOCALITIES}`;
        if (this.isHospitalEnabled && !this.providerType) {
            localityURL += `&types=${HOSPITALS}`;
        }
        const selectedHF = this.rootStore.healthFundStore?.healthFund?.id;
        if (selectedHF) {
            localityURL += `&health_fund=${selectedHF}`;
        }
        return localityURL;
    }

    get hasSpecialty() {
        return (
            !!this.specialty?.id ||
            !!this.interest?.id ||
            !!this.providerType?.id
        );
    }

    get hasSpecialtyAndLocality() {
        return this.hasSpecialty && !!this.searchLocalityStore.selected?.id;
    }

    get hasProviderTypeAndLocality() {
        return (
            !!this.providerType?.id && !!this.searchLocalityStore.selected?.id
        );
    }

    get hasSpecialtyAndState() {
        return this.hasSpecialty && !!this.stateLocalityStore.selected?.id;
    }

    get shouldCallPromotedAPI() {
        // Returns true if there is only one result that is a profile
        // and the profile is not enhanced.
        const results = this.flattenedResults;
        if (results.length !== 1) {
            return false;
        }
        const firstResult = results[0];
        if (firstResult.objectType !== USERPROFILES) {
            return false;
        }
        if (firstResult.extra?.enhanced !== false) {
            return false;
        }
        return true;
    }

    get resultsLength() {
        let length = 0;
        Object.keys(this.results).forEach((key) => {
            const list = this.results[key];
            if (list.length) {
                length += list.length;
            }
        });
        return length;
    }

    get displayPopularSpecialties() {
        return (
            !!this.rootStore?.growthBookStore.experimentInfo
                .featurePopularSpecialties &&
            !this.rootStore.paramStore?.isHfWidget &&
            !this.displayResults &&
            this.hasFocus &&
            this.searchQuery?.length == 0
        );
    }

    get displayCompleteSearchForm() {
        return !!(
            this.specialty?.id ||
            this.interest?.id ||
            this.providerType?.id
        );
    }

    get hasPromotionalImage() {
        const {client} = this.rootStore.paramStore;
        return !!(
            (this.bpImage && (client === BP || client === BP_FHIR)) ||
            ((this.mdImageSmall || this.mdImageLarge) && client === MD)
        );
    }

    get searchTypes() {
        const types = [
            SPECIAL_INTERESTS_IN_LOCALITIES,
            USERPROFILES,
            SPECIALTIES,
            TELEHEALTH_SPECIALTIES,
            SPECIAL_INTERESTS,
            PRACTICE_GROUPS,
            PROVIDER_TYPES_IN_LOCALITIES,
            PROVIDER_TYPES,
        ];
        if (!this.rootStore.paramStore?.isClient) {
            types.push(FACTSHEETS);
            types.push(THREADS);
            types.push(COMMUNITIES);
            types.push(ORGANISATION_PROFILES);
        }
        if (this.searchQuery.trim().split(' ').length > 1) {
            types.push(SPECIALTIES_IN_LOCALITIES);
            types.push(SPECIALTIES_IN_HOSPITALS);
        }
        if (this.isHospitalEnabled) {
            types.push(HOSPITALS);
        }
        return types;
    }

    get flattenedResults() {
        const results = Object.values(this.results).reduce(
            (acc, value) => [...acc, ...value],
            [],
        );
        return results;
    }

    get showStateLocalities() {
        if (!this.specialty?.text) {
            return false;
        }

        return this.specialty.text.includes('Telehealth');
    }

    @autobind
    closeExplainer() {
        localStorageSetItem(
            SEARCH_EXPLAINER_DISABLED,
            this.promotionalMessageModified,
        );
        this.updateStore({
            explainerDisabled: true,
        });
    }

    @autobind
    iframeHandler(type, iframeHeight, containerHeight) {
        switch (type) {
            case 'click':
            case 'mousedown':
                this.updateStore({
                    displayResults: false,
                });
                this.searchLocalityStore.updateStore({
                    showResults: false,
                });
                this.stateLocalityStore.updateStore({
                    showResults: false,
                });
                // reset the height
                document.getElementById('referrals-app').style.height =
                    '230px';
                break;
            case 'orientationchange':
            case 'resize':
                window.parent.postMessage(
                    {iframeHeight, containerHeight},
                    '*',
                );
                break;
            default:
                return null;
        }
    }

    resetPromoted() {
        this.promotedProfiles = [];
        this.promotedSpecialty = '';
        this.promotedLocality = '';
    }

    doSearch() {
        if (!this.searchQuery) {
            return;
        }
        const getParams = {
            q: this.searchQuery,
            'type_limit': 15,
            types: this.searchTypes,
            show: this.rootStore.paramStore?.showParam,
            exc: [HOSPITAL_LOCALITIES],
        };
        const selectedHF = this.rootStore.healthFundStore?.healthFund?.id;
        if (selectedHF) {
            getParams.health_fund = selectedHF;
        }
        if (this.rootStore.paramStore?.skin) {
            getParams.skin = this.rootStore.paramStore?.skin;
        }
        return http.get({
            url: this.SEARCH_URL,
            data: getParams,
        });
    }

    @autobind
    resetSearch() {
        this.updateStore({
            interest: null,
            providerType: null,
            searchQuery: '',
            specialty: null,
            displayResults: false,
            resetCalled: true,
        });
        this.searchLocalityStore.updateStore({
            inputError: {
                showError: false,
                errorMessageWasShown: false,
            },
        });
        this.stateLocalityStore.updateStore({
            inputError: {
                showError: false,
                errorMessageWasShown: false,
            },
        });
        sessionStorageRemoveItem(SEARCH_QUERY);
        this.updateStore({
            searchFieldsVisible: false,
        });
    }

    @autobind
    setMainHeight(mainElement) {
        if (this.displayResults) {
            mainElement.classList.add('search-open');
        } else {
            mainElement.classList.remove('search-open');
        }
    }

    @autobind
    searchError(error) {
        this.throwError(`Search failed with: ${error}`);
    }

    get shouldRedirect() {
        if (this.showStateLocalities) {
            return this.hasSpecialtyAndState;
        }
        return this.hasSpecialtyAndLocality || this.hasProviderTypeAndLocality;
    }

    get searchParams() {
        const {
            healthFundStore,
            interest,
            specialty,
            searchLocalityStore: {selected: locality},
            stateLocalityStore: {selected: state},
            telehealth,
        } = this;
        const params = {
            show: this.rootStore.paramStore?.showParam,
            telehealth,
        };
        if (interest) {
            params['interest'] = interest.id;
            // special interest mapping or special interest parsed from specialty mapping
            params['specialty'] =
                interest?.extra?.specialtyId || interest?.specialtyId;
        }
        if (specialty?.id) {
            params['specialty'] = specialty.id; // specialty mapping
        }
        if (state?.id) {
            params['state'] = state.id;
        }
        if (locality?.id) {
            params['locality'] = locality.id;
        }
        const selectedHF = healthFundStore?.healthFund?.id;
        if (selectedHF) {
            params['health_fund'] = selectedHF;
        }
        return params;
    }

    @autobind
    searchRedirect() {
        if (this.localityFetchedFromStorage) {
            // once redirect has been prevented by the initial storage fetch
            // should function as normal
            this.updateStore({localityFetchedFromStorage: false});
        } else if (this.shouldRedirect) {
            let redirectURL = '';
            const {id, objectType, url} = this.searchLocalityStore.selected;
            if (this.hasSpecialtyAndLocality && objectType === HOSPITALS) {
                redirectURL = url;
                const specialtyIdParam =
                    this.interest?.extra?.specialtyId ||
                    this.interest?.specialtyId ||
                    this.specialty?.id;
                if (specialtyIdParam) {
                    const params = {specialty: specialtyIdParam};
                    redirectURL += `${query.buildQueryString(params, {
                        fullPath: false,
                    })}`;
                }
            } else if (this.hasProviderTypeAndLocality) {
                const params = {
                    hospital: true,
                    locality: id,
                };
                redirectURL = query.buildQueryString(params, {
                    fullPath: true,
                    pathname: 'directory/',
                });
            } else {
                redirectURL = query.buildQueryString(this.searchParams, {
                    fullPath: true,
                    pathname: 'directory/',
                });
            }
            this.sendRedirectEvent(redirectURL);
        }
    }

    @autobind
    hrefForHealthFundWidget(url) {
        const {skin} = this.rootStore.paramStore;
        let appendedURL = new URL(url, window.location);
        if (skin) {
            appendedURL.searchParams.append('skin', skin);
        }
        const selectedHF = this.rootStore.healthFundStore?.healthFund?.id;
        if (selectedHF) {
            appendedURL.searchParams.append('health_fund', selectedHF);
        }
        return appendedURL.href;
    }

    @autobind
    async sendRedirectEvent(url) {
        // if search from any new widget open a new tab
        if (this.isHealthFundWidget) {
            const href = this.hrefForHealthFundWidget(url);
            window.open(href, '_blank');
        } else {
            window.location.href = url;
        }
        this.updateStore({
            displayResults: false,
        });
    }

    get selectedDirectoryLocality() {
        const directoryElement = document.getElementById('directory');
        const hospitalElement = document.getElementById('hospitalDirectory');
        const element = directoryElement || hospitalElement;
        if (element) {
            const data = JSON.parse(element.dataset.directory);
            const locality = data.locality;
            const localityText = `${locality.name} ${
                locality.state.name || ''
            } ${locality['post_code'] || ''}`.trim();
            return locality
                ? {
                      ...locality,
                      name: localityText,
                      text: localityText,
                  }
                : null;
        }
        return null;
    }

    get selectedDirectoryState() {
        const directoryElement = document.getElementById('directory');
        if (directoryElement) {
            const data = JSON.parse(directoryElement.dataset.directory);
            const state = data.state;
            return state || null;
        }
        return null;
    }

    @autobind
    setLocalityFromStorage() {
        const selected = JSON.parse(
            localStorageGetItem(SEARCH_LOCALITY) || null,
        );
        if (selected) {
            this.updateStore({localityFetchedFromStorage: true});
            this.searchLocalityStore.updateStore({selected});
        }
    }

    @autobind
    setStateFromStorage() {
        const selected = JSON.parse(localStorageGetItem(SEARCH_STATE) || null);
        if (selected) {
            this.updateStore({localityFetchedFromStorage: true});
            this.stateLocalityStore.updateStore({
                selected,
            });
        }
    }

    @autobind
    fetchSavedLocality() {
        if (this.selectedDirectoryState || this.selectedDirectoryLocality) {
            // set locality from directory page
            this.updateStore({localityFetchedFromStorage: true});
            if (this.telehealth) {
                this.stateLocalityStore.updateStore({
                    selected: this.selectedDirectoryState,
                });
                localStorageSetItem(
                    SEARCH_STATE,
                    JSON.stringify(this.selectedDirectoryState),
                );
                this.setLocalityFromStorage();
            } else {
                this.searchLocalityStore.updateStore({
                    selected: this.selectedDirectoryLocality,
                });
                localStorageSetItem(
                    SEARCH_LOCALITY,
                    JSON.stringify(this.selectedDirectoryLocality),
                );
                this.setStateFromStorage();
            }
        } else {
            this.setLocalityFromStorage();
            this.setStateFromStorage();
        }
    }

    handlePromotionalImages(response) {
        this.updateStore({
            bpImage: response['bp_image'] || '',
            bpImageUrl: response['bp_image_url'] || '',
            mdImageSmall: response['md_image_small'] || '',
            mdImageLarge: response['md_image_large'] || '',
            mdImageUrl: response['md_image_url'] || '',
        });
    }

    @autobind
    async fetchPromotionalMessage() {
        const response = await http.get({
            url: '/api/professionals/v1/referrals-integration-message/',
        });

        this.handlePromotionalImages(response);
        const promotionalMessage = response?.message;
        const promotionalMessageModified = response?.modified;
        if (promotionalMessage) {
            this.updateStore({
                promotionalMessage,
                promotionalMessageModified,
            });
        }

        const localStorageModified = localStorageGetItem(
            SEARCH_EXPLAINER_DISABLED,
        );
        this.updateStore({
            explainerDisabled:
                localStorageModified === promotionalMessageModified,
        });
    }

    @autobind
    async search() {
        if (this.searchQuery?.trim().length < 2 || this.resultQueryToggled) {
            this.updateStore({
                displayResults: false,
                resultQueryToggled: false,
            });
            return;
        }
        let response;
        let formattedResults = [];
        let facets = [];
        try {
            response = await this.throttledSearch();
            // Can happen if the user has held down backspace and the searchQuery has changed to
            // an empty string before the function above is able to complete.
            // Therefore we have to check if response is defined and exit if not.
            if (!response) {
                return;
            }
            const data = snakeToCamelObjectKeys(response);
            facets = data.facets;
            let totalLimit = 15;
            if (facets.length === 1 && facets[0].type === USERPROFILES) {
                totalLimit = 100;
            }
            formattedResults = formatDisplayedResultsFAP(
                data.facets,
                data.results,
                5, // facet limit
                totalLimit, // total-results-displayed limit
                this.searchQuery,
                this.SORT_ORDER,
            );
        } catch (error) {
            this.searchError(error);
        } finally {
            /* RESPONSE FORMAT:
                {
                    count: 54,
                    facets: [
                        {
                            type: "organisation_profiles",
                            count: 1,
                            show_more: false,
                        },
                        ...,
                    ],
                    next: "http://healthshare.test/api/search/v1/all/?page=2&q=car",
                    previous: null,
                    results: [
                        {
                            id: 2159,
                            object_type: "communities",
                            text: "Cardiomyopathy",
                            sort_text: "Cardiomyopathy",
                            avatar: "",
                            ...
                        },
                        ...,
                    ],
                    show_more: true,
                }
            */

            this.updateStore({
                displayResults: true,
                facets,
                results: formattedResults,
            });
        }
    }

    @autobind
    async searchDirectory() {
        const response = await http.get({
            url: '/api/directory/v1/directory-results-url/',
            data,
        });

        const selectedHF = this.rootStore.healthFundStore?.healthFund?.id;
        if (selectedHF) {
            response.url += `?health_fund=${selectedHF}`;
        }
        return response.url;
    }

    // First input field
    @autobind
    selectSpecialty(specialty) {
        this.updateStore({
            displayResults: false,
            interest: null,
            providerType: null,
            telehealth: false,
            specialty,
        });
    }

    @autobind
    selectTelehealthSpecialty(specialty) {
        specialty.id = specialty.id.match(/(\d+)/g)[0];
        this.updateStore({
            displayResults: false,
            interest: null,
            providerType: null,
            telehealth: true,
            specialty,
        });

        // show state, make sure store is updated last
        setTimeout(() => {
            this.stateLocalityStore.updateStore({
                showResults: true,
            });
        }, 0);
    }

    @autobind
    selectInterest(interest) {
        this.updateStore({
            displayResults: false,
            interest,
            specialty: null,
            providerType: null,
        });
    }

    selectProviderType(providerType) {
        this.updateStore({
            providerType,
            displayResults: false,
            interest: null,
            specialty: null,
            telehealth: false,
        });
    }

    //  Second input field
    @autobind
    selectLocality(selected) {
        this.searchLocalityStore.updateStore({
            selected,
            value: selected.text,
            showResults: false,
        });
    }

    @autobind
    selectState(selected) {
        this.stateLocalityStore.updateStore({
            selected,
            value: selected.text,
            showResults: false,
        });
    }

    @autobind
    rememberSelectedLocality(result) {
        const {extra, id} = result;
        const ids = id.split('-');
        const localityId = ids[ids.length - 1];
        localStorageSetItem(
            SEARCH_LOCALITY,
            JSON.stringify({
                id: localityId,
                name: extra.locality,
            }),
        );
    }

    @autobind
    clickResult(result, evt) {
        if (evt) {
            evt.preventDefault();
        }
        const {id, extra, headline, objectType, text, url} = result;
        switch (objectType) {
            case PROVIDER_TYPES_IN_LOCALITIES:
                this.sendRedirectEvent(url);
                break;
            case PROMOTED_PROFILES:
            case USERPROFILES:
                this.sendRedirectEvent(url);
                break;
            case TELEHEALTH_SPECIALTIES:
                this.selectTelehealthSpecialty(result);
                break;
            case SPECIALTIES:
                this.selectSpecialty(result);
                break;
            case SPECIAL_INTERESTS:
                this.selectInterest(result);
                break;
            case PROVIDER_TYPES:
                this.selectProviderType(result);
                break;
            case SPECIALTIES_IN_LOCALITIES:
                this.rememberSelectedLocality(result);

                this.sendRedirectEvent(url);
                break;
            case SPECIALTIES_IN_HOSPITALS:
                let redirectURL = url;
                if (extra.specialty?.id) {
                    redirectURL += query.buildQueryString(
                        {specialty: extra.specialty.id},
                        {fullPath: false},
                    );
                }
                this.sendRedirectEvent(redirectURL);
                break;
            case SPECIAL_INTERESTS_IN_LOCALITIES:
                this.rememberSelectedLocality(result);

                this.sendRedirectEvent(url);
                break;
            case COMMUNITIES:
            case FACTSHEETS:
            case THREADS:
            case ORGANISATION_PROFILES:
            case PRACTICE_GROUPS:
                this.sendRedirectEvent(url);
                break;
            case LOCALITIES:
                this.selectLocality(result);
                break;
            case STATE:
                this.selectState(result);
                break;
            case HOSPITALS:
                if (this.displayCompleteSearchForm) {
                    this.selectLocality(result);
                } else {
                    this.sendRedirectEvent(url);
                }
                break;

            default:
                return null;
        }
    }

    @autobind
    handleHighlight(searchType, increment, results) {
        let elementID = '';
        switch (searchType) {
            case SEARCH_QUERY:
                elementID = 'search';
                break;
            case SEARCH_LOCALITY:
            case SEARCH_STATE:
                elementID = 'location';
                break;
            default:
                break;
        }

        const currentResultId = document.activeElement.parentElement.id;
        const currentIndex = results.findIndex(
            (res) => `${res.id}` === currentResultId,
        );

        let newIndex = currentIndex + increment;
        if (newIndex < 0 || newIndex >= results.length) {
            newIndex = -1;
        }

        if (newIndex === -1) {
            document.getElementById(elementID)?.focus();
        } else {
            document
                .getElementById(results[newIndex]?.id)
                ?.firstChild?.focus();
        }
    }

    @autobind
    clearSearch(searchType) {
        switch (searchType) {
            case SEARCH_QUERY:
                this.updateStore({
                    searchQuery: '',
                    specialty: null,
                    interest: null,
                });
                break;
            case SEARCH_LOCALITY:
                this.searchLocalityStore.updateStore({
                    value: '',
                    selected: {},
                    showResults: false,
                });
                break;
            case SEARCH_STATE:
                this.stateLocalityStore.updateStore({
                    value: '',
                    selected: {},
                });
                break;
            default:
                break;
        }
    }

    @autobind
    handleKeyboardNavigation(event) {
        let isSearchInput = document.activeElement.id.match(
            /^(search|location)$/i,
        );
        const isResult = document.activeElement.classList.contains(
            'result-body',
        );
        const key = event.key;

        const searchType = this.displayResults
            ? SEARCH_QUERY
            : this.searchLocalityStore.showResults
            ? SEARCH_LOCALITY
            : this.stateLocalityStore.showResults
            ? SEARCH_STATE
            : null;

        let results = toJS(this.flattenedResults);
        if (searchType === SEARCH_LOCALITY) {
            results = toJS(this.searchLocalityStore.results);
        } else if (searchType === SEARCH_STATE) {
            results = toJS(this.stateLocalityStore.results.results);
        }

        if (isSearchInput) {
            switch (key) {
                case ARROW_DOWN:
                case ARROW_UP:
                    event.preventDefault();
                    document
                        .getElementById(
                            results[
                                key === ARROW_DOWN ? 0 : results.length - 1
                            ]?.id,
                        )
                        ?.firstChild?.focus();
                    break;
                case ENTER:
                    event.preventDefault();
                    break;
                case ESC:
                case ESCAPE:
                    event.preventDefault();
                    this.clearSearch(searchType);
                    break;
                case ENTER:
                default:
                    break;
            }
        }

        if (isResult) {
            switch (key) {
                case ARROW_UP:
                    event.preventDefault();
                    this.handleHighlight(searchType, -1, results);
                    break;
                case ARROW_DOWN:
                    event.preventDefault();
                    this.handleHighlight(searchType, 1, results);
                    break;
                case ENTER:
                    break;
                case TAB:
                    // hide results on tab of final element
                    const currentResultId =
                        document.activeElement.parentElement.id;
                    const currentIndex = results.findIndex(
                        (res) => `${res.id}` === currentResultId,
                    );
                    if (currentIndex >= results.length - 1) {
                        this.updateStore({
                            displayResults: false,
                        });
                        this.searchLocalityStore.updateStore({
                            showResults: false,
                        });
                        this.stateLocalityStore.updateStore({
                            showResults: false,
                        });
                    }
                    break;
                case ESC:
                case ESCAPE:
                    event.preventDefault();
                    this.clearSearch(searchType);
                    document.getElementById('search')?.focus();
                    break;
                default:
                    // only focus input on alphanumeric and special characters
                    if (
                        /[ A-Za-z0-9!'^+%&/()=?_\-~`;#$½{[\]}\\|<>@,]/gi.test(
                            key,
                        )
                    ) {
                        switch (searchType) {
                            case SEARCH_QUERY:
                                document.getElementById('search')?.focus();
                                break;
                            case SEARCH_LOCALITY:
                            case SEARCH_STATE:
                                document.getElementById('location')?.focus();
                                break;
                            default:
                                break;
                        }
                    }
                    break;
            }
        }
    }
}
