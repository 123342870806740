import * as Sentry from '@sentry/browser';
import {Integrations as ApmIntegrations} from '@sentry/apm';
import {detect} from 'detect-browser';
import setupSentry from 'common/setupSentry.js';

function parseBrowserVersion(version) {
    return parseInt(version?.split('.')[0]);
}

Sentry.init({
    dsn: 'https://0bfe145d33a649c59eb9cef4fbf64081@app.getsentry.com/90683',
    integrations: [new ApmIntegrations.Tracing()],
    tracesSampleRate: 0.002,
    beforeSend(event, hint) {
        const browser = detect();
        if (browser.name === 'chrome') {
            const version = parseBrowserVersion(browser.version);
            if (version < 79) {
                return;
            }
        }
        if (browser.name === 'firefox') {
            const version = parseBrowserVersion(browser.version);
            if (version < 70) {
                return;
            }
        }
        return event;
    },
});

setupSentry(Sentry);
