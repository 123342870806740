import {computed, configure, observable, makeObservable, reaction} from 'mobx';

import autobind from 'common/decorators/autobind.js';
import http from 'http.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';
import Store from 'core/stores/Store.js';
import {HOSPITALS, LOCALITIES, STATE} from 'base/search.js';

export const STATES = [
    {id: 1, text: 'ACT', objectType: STATE},
    {id: 2, text: 'NSW', objectType: STATE},
    {id: 3, text: 'NT', objectType: STATE},
    {id: 4, text: 'QLD', objectType: STATE},
    {id: 5, text: 'SA', objectType: STATE},
    {id: 6, text: 'TAS', objectType: STATE},
    {id: 7, text: 'VIC', objectType: STATE},
    {id: 8, text: 'WA', objectType: STATE},
];

configure({enforceActions: 'observed'});

export class SearchLocalityStore extends Store {
    constructor(parentStore) {
        super();

        makeObservable(this, {
            inputError: observable,
            value: observable,
            results: observable,
            selected: observable,
            showResults: observable,
            url: observable,

            suggestions: computed,
            searchParams: computed,
        });

        this.parentStore = parentStore;

        reaction(
            () => this.showResults && this.value,
            () => this.fetchAutocomplete(),
            {delay: 250},
        );
    }

    inputError = {
        showError: false,
        errorWasShown: false,
    };
    value = '';
    results = [];
    selected = {};
    showResults = false;
    url = '';

    sortOrder = [LOCALITIES, HOSPITALS];

    get suggestions() {
        return this.transformSuggestions(this.results);
    }

    get searchParams() {
        return this.transformSearchParams({
            q: this.selected?.name || this.value,
        });
    }

    @autobind
    async fetchAutocomplete() {
        let response = await http.get({
            url: this.parentStore.localityURL,
            data: this.searchParams,
        });

        if (response.hasOwnProperty('results')) {
            response = response.results;
        }

        const results = this.transformResponse(
            Object.values(snakeToCamelObjectKeys(response)),
        );
        this.updateStore({results});
    }

    transformResponse(storeData) {
        return storeData.sort((a, b) => {
            // sort by default sort order first
            const aIndex = this.sortOrder.findIndex((s) => s === a.objectType);
            const bIndex = this.sortOrder.findIndex((s) => s === b.objectType);
            return aIndex - bIndex;
        });
    }

    transformSearchParams(params) {
        params['show'] = this.parentStore.showParam;
        params['type_limit'] = 8;
        if (!this.parentStore.localityURL.includes('types=hospitals')) {
            params['type_limit'] = 15;
        }
        if (this.parentStore?.paramStore?.skin) {
            params.skin = this.parentStore.paramStore.skin;
        }
        return params;
    }

    transformSuggestions(suggestions) {
        const mappedSuggestions = suggestions.map((result) => {
            switch (result.objectType) {
                case HOSPITALS:
                    result.type = 'hospital';
                    break;
                case LOCALITIES:
                    result.type = 'locality';
                    break;
            }
            result.name = result.text;
            return result;
        });

        return mappedSuggestions.length > 0
            ? {results: mappedSuggestions}
            : {};
    }
}

export class StateLocalityStore extends SearchLocalityStore {
    constructor() {
        super();
        makeObservable(this, {
            filteredStates: observable,
        });

        reaction(
            () => this.value,
            () => this.filterStates(),
        );
    }

    filteredStates = {results: STATES};
    isStateStore = true;

    @autobind
    filterStates() {
        let filteredStates = [];

        if (this.selected?.id) {
            filteredStates = STATES;
        } else {
            filteredStates = [...STATES].reduce((acc, state) => {
                if (state.text.startsWith(this.value.toUpperCase())) {
                    acc.push(state);
                }
                return acc;
            }, []);
        }

        this.updateStore({
            filteredStates: {results: filteredStates},
        });
    }

    @autobind
    fetchAutocomplete() {
        this.updateStore({
            results: this.filteredStates,
        });
    }

    get suggestions() {
        return {results: this.filteredStates};
    }

    transformSearchParams(params) {
        return params;
    }

    transformSuggestions(suggestions) {
        return suggestions;
    }
}
